<template>
  <div class="line-operator-item-component">
    <div class="slider-wrapper">
      <el-slider v-model="value" v-bind="domAttrs" :format-tooltip="option.formatTooltip"> </el-slider>
      <span class="txt">
        {{ option.formatTooltip(value) }}
      </span>
    </div>
    <el-input-number v-model="value" v-bind="domAttrs"></el-input-number>
  </div>
</template>

<script>
import { fabricInstanceMixin } from '../../../../mixins'

export default {
  mixins: [fabricInstanceMixin],

  props: {
    prop: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      value: 0
    }
  },

  created() {
    this.initWatcher()
  },

  computed: {
    option({ prop }) {
      const list = {
        angle: {
          watchProp: 'curFabircActiveObjectAngle',
          updateHandlerProp: 'UPDATE_CUR_FABRIC_ACTIVE_OBJECT_ANGLE',
          step: 1,
          max: 360,
          min: 0,
          valueFormat(value) {
            return +(value || 0).toFixed(2)
          },
          formatTooltip(value) {
            return +(value || 0).toFixed(2)
          }
        },
        scaleX: {
          watchProp: 'curFabircActiveObjectScale',
          updateHandlerProp: 'UPDATE_CUR_FABRIC_ACTIVE_OBJECT_SCALE',
          fillPropList: ['scaleY'],
          step: 0.01,
          max: 1,
          min: 0.01,
          valueFormat(value) {
            return +(value || 0).toFixed(2)
          },
          formatTooltip(value) {
            return +(value || 0).toFixed(2)
          }
        },
        scaleY: {
          watchProp: 'curFabircActiveObjectScale',
          updateHandlerProp: 'UPDATE_CUR_FABRIC_ACTIVE_OBJECT_SCALE',
          fillPropList: ['scaleX'],
          step: 0.01,
          max: 1,
          min: 0.01,
          valueFormat(value) {
            return +(value || 0).toFixed(2)
          },
          formatTooltip(value) {
            return +(value || 0).toFixed(2)
          }
        }
      }
      return list[prop]
    },

    domAttrs({ option }) {
      const { step, min, max } = option
      return {
        step,
        min,
        max
      }
    }
  },

  watch: {
    value(newVal) {
      const { updateHandlerProp, fillPropList } = this.option
      if (!this.curFabircActiveObject) return
      this.curFabircActiveObject.setOptions({
        [this.prop]: newVal
      })
      //vuex 更新时候是一起更新的 如只监听了scale 但是更新的是scaleX，更新时要把scaleY带上
      if (Array.isArray(fillPropList)) {
        const tmpObj = {
          [this.prop]: newVal
        }
        fillPropList.map((prop) => {
          tmpObj[prop] = this.curFabircActiveObject[prop]
        })
        //更新scale对象
        this[updateHandlerProp](tmpObj)
      } else {
        this[updateHandlerProp](newVal)
      }
      this.canvasModifiedHandler()
    }
  },

  methods: {
    initWatcher() {
      let unWatch = this.$watch(
        () => {
          return this[this.option.watchProp]
        },
        this.watchHandler,
        {
          immediate: true
        }
      )

      this.$once('destroyed', () => {
        unWatch()
      })
    },

    watchHandler() {
      const { valueFormat } = this.option
      const prop = this.prop
      const activeObject = this.getActiveObject()
      if (activeObject) {
        this.value = valueFormat(activeObject[prop])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slider-wrapper {
  @include jcFlex;
  .el-slider {
    flex: 1;
  }
  .txt {
    margin-left: 15px;
  }
}
</style>